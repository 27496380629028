* {
  font-family: "Inter", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 0.9rem !important;
  letter-spacing: 0px;
}

:root {
  --gray-300: #333;
  --gray-400: #444;
  --gray-600: #666;
  --gray-80: #ccc;
  --gray-60: rgb(236, 236, 236);
  --primary: #4169e1;
  --blue-primary-10: rgba(2, 103, 255, 0.3);
  --blue-primary-500: rgba(65, 105, 225, 0.5);
  --green: #129d5a;
  --green-light: #a9ebcb;
  --orange: #d2691e;
  --red: #ea0043;
  --red-light: #f5b7b7;
  --blue-500: #3f51b5;
  --blue-link: #d0dbf0;
  --white: #fff;
  --ck-ui-component-min-height: 30px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cedeee;
  border-radius: 10px;
}

html {
  background-color: #fff !important;
  overflow-y: auto !important;
}

html {
  font-size: 100%;
}

.PagesGridView .PageItem .linkContainer .link {
  color: red !important;
}

body {
  /* position: absolute; */
  width: 100%;
  /* height: 100vh; */
  top: 0;
  /* background-color: rgba(241, 246, 251) !important; */
  /* background-image: url("./assets/bk.jpg"); */
}

.iframe-internal {
  overflow: scroll;
  min-height: 100vh;
}

ul li {
  list-style: none;
}

.menu-label {
  font-weight: 500;
}

strong {
  font-weight: 600 !important;
}

.MuiChip-root {
  border-radius: 10px !important;
}

.MuiCircularProgress-root {
  animation: circular-rotate 0.2s linear infinite;
}

.logo {
  width: 80%;
}

.navbar-logo {
  width: 220px !important;
}

.navbar-title {
  padding: 13px;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
}

.menu-router-link {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  margin: 5px 5px 0px 8px;
}

.menu-router-link:hover {
  background-color: #e7ecf3;
  border-radius: 20px;
}

.titleMenu {
  padding-top: 6px;
}

.menu-list a {
  padding: 10px !important;
}

button {
  border-radius: 4px !important;
  border: none;
  outline: none;
  cursor: pointer;
}

.box_login {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  max-width: 350px;
  -webkit-box-shadow: 0px 0px 13px #cccccc !important;
  box-shadow: 0px 0px 13px #cccccc;
}

.content-first {
  box-shadow: 0px 0px 7px #dddddd;
}

.text-danger {
  color: rgb(230, 111, 51);
}

.MuiMenu-paper {
  transition-duration: 0s !important;
}

/* .MuiListItem-button {
  transition-duration: 0s !important;
} */

.contentTab {
  background-color: #ffffff;
  min-height: 1000px;
  padding: 20px;
}

.appbarTab button svg {
  font-size: 20px;
}

.MuiTableCell-root {
  padding: 4px !important;
}

.rowCustom th {
  background-color: #cad6f0;
  padding: 13px !important;
  font-weight: bold !important;
  color: #555555;
}

.logoMenu {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoMenu img {
  height: 50px;
  display: block;
  width: 100%;
}

@keyframes load_wide {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.8;
  }

  70% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.load_wide {
  -webkit-animation: load_wide 0.75s linear infinite;
  -moz-animation: load_wide 0.75s linear infinite;
  -ms-animation: load_wide 0.75s linear infinite;
  -o-animation: load_wide 0.75s linear infinite;
  animation: load_wide 0.75s linear infinite;
}

.load_wide svg {
  font-size: 20px !important;
}

svg {
  font-size: 20px !important;
}

.generalLoad {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  padding-top: 250px;
  background-color: #f6f2f2;
  z-index: 1001;
  animation: opacityGeneral 0.4s forwards;
}

.flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

/* react-modal */

.modal-default-wrapper-overlay {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.modal-default-wrapper {
  z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-height: fit-content;
  width: "auto";
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;

  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal-default-wrapper-simple {
  z-index: 999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-height: fit-content;
  width: "auto";
  border-radius: 5px;
  padding: 15px;

  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.MuiSnackbar-root {
  bottom: 0 !important;
  z-index: 999999999 !important;
}

.MuiDialog-root {
  z-index: 9999999 !important;
}

.MuiPopover-root {
  z-index: 9999999 !important;
}

.MuiPickersPopper-root {
  z-index: 999999999 !important;
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  text-indent: 40px !important;
  padding-left: 0 !important;
}
