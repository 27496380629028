.loading-spinner {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner__container {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  border-top: 4px solid var(--primary);
  border-right: 4px solid #ccc;
  border-bottom: 4px solid #ccc;
  border-left: 4px solid #ccc;

  animation: rotate 1s infinite linear;
}

.loading-spinner__circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 4px solid var(--primary);
  z-index: 999;
}
